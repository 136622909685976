var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MbnTableTools',{attrs:{"search":_vm.search,"limit":_vm.limit},on:{"updateSearchQuery":function($event){_vm.search = $event},"updateLimit":_vm.updateLimit,"resetSearch":_vm.resetSearch}}),_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('sidebar.creatives')))])]),_c('CCardBody',[(_vm.error)?_c('MbnErrorMessage'):_c('CDataTable',{attrs:{"fields":_vm.fields,"items":_vm.tableItems,"striped":"","add-table-classes":"section-table","no-items-view":{ noItems: _vm.$t('ui.no_items_available') },"sorter":{ external: false, resetable: false },"sorter-value":{ column: 'id', asc: false },"loading":_vm.isLoading,"hover":""},scopedSlots:_vm._u([{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.active)}},[_vm._v(" "+_vm._s(item.active ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"moderated",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.moderated)}},[_vm._v(" "+_vm._s(item.moderated ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"approved",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.approved)}},[_vm._v(" "+_vm._s(item.approved ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"dateStart",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.normalizeDate(item.dateStart))+" ")])]}},{key:"dateEnd",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.normalizeDate(item.dateEnd))+" ")])]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.approve'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.accept(item)}}},[_c('CIcon',{attrs:{"name":"cil-thumb-up"}})],1),_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.not_approve'),"size":"sm","color":"danger"},on:{"click":function($event){return _vm.decline(item)}}},[_c('CIcon',{attrs:{"name":"cil-thumb-down"}})],1)],1)])]}}])}),(_vm.isShowedPagination)?_c('CPagination',{attrs:{"pages":_vm.totalPages,"active-page":_vm.activePage,"size":"sm"},on:{"update:activePage":_vm.onChangePage}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }