<template>
  <div>
    <MbnTableTools
      :search="search"
      :limit="limit"
      @updateSearchQuery="search = $event"
      @updateLimit="updateLimit"
      @resetSearch="resetSearch"
    />

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <span>{{ $t('sidebar.creatives') }}</span>
          </CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="tableItems"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              :sorter="{ external: false, resetable: false }"
              :sorter-value="{ column: 'id', asc: false }"
              :loading="isLoading"
              hover
            >
              <template #active="{ item }">
                <td>
                  <CBadge :color="getBadge(item.active)">
                    {{ item.active ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #moderated="{ item }">
                <td>
                  <CBadge :color="getBadge(item.moderated)">
                    {{ item.moderated ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #approved="{ item }">
                <td>
                  <CBadge :color="getBadge(item.approved)">
                    {{ item.approved ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #dateStart="{ item }">
                <td>
                  {{ normalizeDate(item.dateStart) }}
                </td>
              </template>
              <template #dateEnd="{ item }">
                <td>
                  {{ normalizeDate(item.dateEnd) }}
                </td>
              </template>
              <template #show_details="{ item }">
                <td>
                  <div class="d-flex">
                    <CButton :title="$t('ui.approve')" size="sm" class="mr-3" color="info" @click="accept(item)">
                      <CIcon name="cil-thumb-up" />
                    </CButton>
                    <CButton :title="$t('ui.not_approve')" size="sm" class="mr-3" color="danger" @click="decline(item)">
                      <CIcon name="cil-thumb-down" />
                    </CButton>
                  </div>
                </td>
              </template>
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="activePage"
              size="sm"
              @update:activePage="onChangePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_CREATIVES_LIST, EDIT_CREATIVE } from '@/store/action-types/admin';
import { limits } from '@/helpers/const';
import { normalizeDate } from '@/helpers/normalize-date';
import { getBadge } from '@/helpers/utils';
import searchByTable from '@/helpers/search-by-table';
import MbnTableTools from '@/components/TableTools';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'AdminCreatives',
  components: {
    MbnTableTools,
    MbnErrorMessage,
  },
  data() {
    return {
      limit: this.$route.query.limit || limits[0].value,
      search: '',
      filtered: '',
      activePage: Number(this.$route.query.page || '1'),
    };
  },
  computed: {
    ...mapState({
      creativesList: (state) => state.admin.creatives.list,
      isLoading: (state) => state.admin.creatives.isLoading,
      error: (state) => state.admin.creatives.error,
      total: (state) => state.admin.creatives.total,
      isSending: (state) => state.admin.creatives.isSending,
    }),
    tableItems() {
      return this.filtered ? this.filtered : this.creativesList;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    offset() {
      return this.activePage * this.limit - this.limit;
    },
    isShowedPagination() {
      return this.totalPages > 1 && !this.filtered;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('creatives.id') },
        { key: 'campaignId', label: this.$t('creatives.campaign_id') },
        { key: 'userId', label: this.$t('creatives.user_id') },
        { key: 'name', label: this.$t('creatives.name') },
        { key: 'type', label: this.$t('creatives.type') },
        { key: 'clickUrl', label: this.$t('creatives.click_url') },
        { key: 'cpm', label: this.$t('creatives.cpm') },
        { key: 'linkType', label: this.$t('creatives.link_type') },
        { key: 'dateStart', label: this.$t('creatives.date_start') },
        { key: 'dateEnd', label: this.$t('creatives.date_end') },
        { key: 'approved', label: this.$t('creatives.approved') },
        { key: 'active', label: this.$t('creatives.active') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  watch: {
    search(value) {
      this.filterData(value);
    },
    activePage() {
      this.fetchCreatives();
    },
    limit() {
      this.fetchCreatives();
    },
  },
  mounted() {
    this.fetchCreatives();
  },
  methods: {
    ...mapActions('admin', [GET_CREATIVES_LIST, EDIT_CREATIVE]),
    resetSearch() {
      this.search = '';
    },
    updateLimit(value) {
      this.limit = value;
      this.activePage = 1;
      this.$router.push({ query: { limit: this.limit } });
    },
    filterData(query) {
      this.filtered = searchByTable(query, this.creativesList);
    },
    getBadge(status) {
      return getBadge(status);
    },
    normalizeDate(date) {
      return normalizeDate(date);
    },
    onChangePage(page) {
      this.activePage = page;
      this.$router.push({ query: { limit: this.limit, page } });
    },
    fetchCreatives() {
      this.GET_CREATIVES_LIST({
        limit: this.limit,
        offset: this.offset,
      });
    },
    accept(record) {
      this.update(record, { field: 'approved', value: true });
    },
    decline(record) {
      this.update(record, { field: 'approved', value: false });
    },
    update(record, prop) {
      const creative = { ...record };
      creative[prop.field] = prop.value;
      this.EDIT_CREATIVE({ id: creative.id, creative })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.fetchCreatives();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
